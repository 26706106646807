body {
    background-color: #eaeaea !important;
    background-size: cover;
    /*background: rgba(0,0,0,0.1);*/
    height: 100vh;
}

.ui.inverted.menu {
    background-color: #05386b !important;
}

.ui.inverted.menu .active.item {
    background-color: hsla(0, 0%, 100%, 0.15) !important;
}

.ui.main.container {
    margin-top: 7em;
}

.ui.masthead {
    display: flex;
    align-items: center;

    background-color: #07529c !important;
    height: 100vh;
}

ui.masthead.ui.header {
    font-size: 4em;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-calendar {
    width: 100%;
    border: none;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}
